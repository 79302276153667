// TODO: Convert this to the top 5 of each

export const INGREDIENT_DATA = {
    '🥛 Milk, egg products': [
        'Butter',
        'Buttermilk',
        'Cottage cheese',
        'Cream',
        'Egg',
        'Ground nutmeg',
        'Milk',
        'Mozzarella',
        'Parmesan cheese',
        'Vanilla',
        'Yogurt'],
    '🥜 Fats and Oils': ['Canola oil', 'Mayonnaise', 'Oil', 'Oil cooking spray'],
    '🍎 Fruits': ['Apple',
        'Apricot',
        'Banana',
        'Blueberries',
        'Grape',
        'Mango',
        'Oranges',
        'Peach',
        'Pear',
        'Pears',
        'Pineapple',
        'Raisins',
        'Strawberries'],
    '🌾 Grains': ['Almond',
        'Barley',
        'Cashew',
        'Chocolate chips',
        'Cookies',
        'Corn tortillas',
        'Flour',
        'Linguine',
        'Oats',
        'Pecans',
        'Quinoa',
        'Rice',
        'Sugar',
        'Salt',
        'Walnuts',
        'Wheat',
        'Wheat bran'],
    '🌿 Herbs and spices': ['Basil',
        'Cayenne',
        'Cayenne pepper',
        'Chili powder',
        'Cilantro',
        'Cloves',
        'Cumin',
        'Curry powder',
        'Ground chipotle chile',
        'Ground cinnamon',
        'Ground cumin',
        'Ground ginger',
        'Marjoram',
        'Mint leaves',
        'Oregano',
        'Paprika',
        'Parsley',
        'Powder',
        'Rosemary',
        'Sage',
        'Tarragon',
        'Thyme'],
    '🍗 Meats': ['Beef',
        'Beef round',
        'Chicken',
        'Salmon',
        'Shrimp',
        'Sirloin steak',
        'Tuna',
        'Turkey'],
    '🍝 Pasta, rice, pulses': ['Beans',
        'Black beans',
        'Black-eyed peas',
        'Chickpeas',
        'Kidney beans',
        'Lentils',
        'Peas',
        'Split peas',
        'White beans',
        'Pasta',
        'Egg noodles',
        'Egg roll wrappers',
        'Ramen noodles',
        'Rice noodles',
        'Spaghetti'
    ],
    '🥦 Vegetables ': [
        'Bell pepper',
        'Broccoli',
        'Brussels sprouts',
        'Carrot',
        'Cauliflower',
        'Celery',
        'Chard',
        'Chiles',
        'Garlic',
        'Kale',
        'Leeks',
        'Lettuce',
        'Mushrooms',
        'Olives',
        'Onion',
        'Onions',
        'Peppers',
        'Scallion',
        'Shallot',
        'Spinach',
        'Tomato',
        'Tomatoes',
        'Zucchini'
    ],
}


export const FITNESS_GOALS: { [key: string]: string } = {
    "Gain muscle": "muscleGain",
    "Lose fat": "fatLoss",
    "Lose weight": "weightLoss"
}

export const FITNESS_GOALS_PARAMETERS: { [key: string]: string[] } = {
    "muscleGain": ["protein", "carbohydrates"],
    "fatLoss": ["protein", "carbohyddrates", "sugar", "fat"],
    "weightLoss": ["protein", "carbohydrates"],
}